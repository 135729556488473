import Leaf1 from '../images/Leaf1.png'
import Leaf2 from '../images/Leaf2.png'
import Leaf3 from '../images/Leaf3.png'
import Leaf4 from '../images/Leaf4.png'
import Leaf5 from '../images/Leaf5.png'
import Leaf6 from '../images/Leaf6.png'
import Leaf7 from '../images/Leaf7.png'
import Leaf8 from '../images/Leaf8.png'
import Leaf9 from '../images/Leaf9.png'
import Leaf10 from '../images/Leaf10.png'
import Leaf11 from '../images/Leaf11.png'
import Leaf12 from '../images/Leaf12.png'

const leafs = [
  Leaf1,
  Leaf2,
  Leaf3,
  Leaf4,
  Leaf5,
  Leaf6,
  Leaf7,
  Leaf8,
  Leaf9,
  Leaf10,
  Leaf11,
  Leaf12,
]

export default (): string => {
  const num = Math.floor(Math.random() * leafs.length)
  return leafs[num]
}
