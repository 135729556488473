import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Card } from '@jsluna/react'

const TermsAndConditions: React.FC<{ brand: string }> = ({ brand }) => {
  const SainsburysTermsAndConditions = (
    <Card>
      <h1 className="ln-u-color-brand-1 ln-u-text-align-center">
        Prize Draw Terms & Conditions
      </h1>
      <Ol>
        <Li>
          <p>
            By completing the questionnaire and &quot;opting in&quot; you will
            automatically be entered into the prize draw and agree to these
            terms and conditions. This Prize Draw is open to residents of the
            United Kingdom aged 18 or over, except for agencies or anyone
            professionally associated with this Prize Draw.
          </p>
        </Li>
        <Li>
          <p>
            This Prize Draw closes at 23:59 on the last day of each calendar
            month. Only one entry per person per month. Following each draw,
            Sainsbury’s reserves the right to (i) modify, suspend, cancel or
            terminate this promotion or; (ii) amend these terms and conditions
            (but will use reasonable endeavours to ensure entrants are not
            disadvantaged in any draw they have entered); and (iii) cancel or
            refuse any individual’s entry in breach of these terms and
            conditions.
          </p>
        </Li>
        <Li>
          <p>
            There will be ten winners selected each calendar month from all of
            the entrants in that calendar month. The winners will be randomly
            drawn by an independent process on the second Wednesday of the
            calendar month following entry. The winner will be notified within
            14 days of the draw in the relevant month. Sainsbury’s decision will
            be final and no correspondence will be entered into. Ten prizes of
            100,000 (one hundred thousand) Nectar points (worth £500 in store)
            will be won each month.
          </p>
        </Li>
        <Li>
          <p>
            Winners who have a Nectar account will be automatically credited
            with their prize. Winners who do not have a Nectar account must
            claim their Prize within 14 days of being notified otherwise the
            prize will be forfeited and an alternative winner will be drawn.
            Winners who do not have a Nectar account will be given an
            opportunity to open a Nectar account to claim their prize. Each
            Prize is non-transferable, non-refundable and non-negotiable. There
            are no cash alternatives to the stated prize. Sainsbury’s reserves
            the right to substitute the Prize for one of equal or greater value
            in the event of circumstances outside of its control.
          </p>
        </Li>
        <Li>
          <p>
            Sainsbury’s does not accept responsibility for network, computer,
            hardware or software failures of any kind, which may restrict or
            delay the sending or receipt of your entry. Entries must not be sent
            in through agents or third parties.
          </p>
        </Li>
        <Li>
          <p>
            We will use your contact details to inform you if you have been
            successful in the prize draw. We will not use your contact details
            for any other marketing purpose. Any personal data relating to
            entrants will be used solely for the purposes of this Promotion by
            the Promoter and/or by any agent appointed to it to assist with
            running the Promotion on behalf of the Promoter and will not be
            disclosed to a third party for any other purpose. Entrants’ personal
            data will be handled in accordance with the Promoter’s privacy
            policy, available{' '}
            <a href="https://privacy-hub.sainsburys.co.uk/privacy-policy/">
              here
            </a>
            .
          </p>
        </Li>
        <Li>
          <p>
            Details of the prize winner can be obtained for a period of 12 weeks
            at the <Link to="/winners/">Winners page</Link>. We will publish the
            surname and county of residence of each winner. If a winner objects
            to the publication of this information they may notify the Promoter
            within 14 days of their original winning notification.
          </p>
        </Li>
        <Li>
          <p>
            The Promoter is: Sainsbury’s Supermarkets Ltd, 33 Holborn, London,
            EC1N 2HT.
          </p>
        </Li>
        <Li>
          <p>
            These terms and conditions shall be governed by English law and the
            English Courts shall have exclusive jurisdiction to hear any
            disputes. Promoter: Sainsbury’s Supermarkets Ltd, 33 Holborn,
            London, EC1N 2HT.
          </p>
        </Li>
      </Ol>
    </Card>
  )

  const ArgosTermsAndConditions = (
    <Card>
      <h1 className="ln-u-color-brand-1 ln-u-text-align-center">
        Prize Draw Terms & Conditions
      </h1>
      <Ol>
        <Li>
          <p>
            By completing the questionnaire and &quot;opting in&quot; you will
            automatically be entered into the prize draw and agree to these
            terms and conditions. This Prize Draw is open to residents of the
            United Kingdom aged 18 or over, except for agencies or anyone
            professionally associated with this Prize Draw.
          </p>
        </Li>

        <Li>
          <p>
            This Prize Draw closes at 23:59 on the last day of each calendar
            month. Only one entry per person per month. Following each draw,
            Argos reserves the right to (i) modify, suspend, cancel or terminate
            this promotion or; (ii) amend these terms and conditions (but will
            use reasonable endeavours to ensure entrants are not disadvantaged
            in any draw they have entered); and (iii) cancel or refuse any
            individual’s entry in breach of these terms and conditions.
          </p>
        </Li>
        <Li>
          <p>
            There will be ten winners selected each calendar month from all of
            the entrants in that calendar month. The winners will be randomly
            drawn by an independent process on the second Wednesday of the
            calendar month following entry. The winner will be notified within
            14 days of the draw in the relevant month. Argos decision will be
            final and no correspondence will be entered into. Ten prizes of
            100,000 (one hundred thousand) Nectar points (worth £500 in store)
            will be won each month.
          </p>
        </Li>
        <Li>
          <p>
            Winners who have a Nectar account will be automatically credited
            with their prize. Winners who do not have a Nectar account must
            claim their Prize within 14 days of being notified otherwise the
            prize will be forfeited and an alternative winner will be drawn.
            Winners who do not have a Nectar account will be given an
            opportunity to open a Nectar account to claim their prize. Each
            Prize is non-transferable, non-refundable and non-negotiable. There
            are no cash alternatives to the stated prize. Argos reserves the
            right to substitute the Prize for one of equal or greater value in
            the event of circumstances outside of its control.
          </p>
        </Li>
        <Li>
          <p>
            Argos does not accept responsibility for network, computer, hardware
            or software failures of any kind, which may restrict or delay the
            sending or receipt of your entry. Entries must not be sent in
            through agents or third parties.
          </p>
        </Li>
        <Li>
          <p>
            We will use your contact details to inform you if you have been
            successful in the prize draw. We will not use your contact details
            for any other marketing purpose. Any personal data relating to
            entrants will be used solely for the purposes of this Promotion by
            the Promoter and/or by any agent appointed to it to assist with
            running the Promotion on behalf of the Promoter and will not be
            disclosed to a third party for any other purpose. Entrants’ personal
            data will be handled in accordance with the Promoter’s privacy
            policy, available{' '}
            <a href="https://privacy-hub.sainsburys.co.uk/privacy-policy/">
              here
            </a>
            .
          </p>
        </Li>
        <Li>
          <p>
            Details of the prize winner can be obtained for a period of 12 weeks
            at the <Link to="/winners/">Winners page</Link>. We will publish the
            surname and county of residence of each winner. If a winner objects
            to the publication of this information they may notify the Promoter
            within 14 days of their original winning notification.
          </p>
        </Li>
        <Li>
          <p>
            The Promoter is: Argos Limited of Avebury, 489-499 Avebury
            Boulevard, Milton Keynes, United Kingdom, MK9 2NW
          </p>
        </Li>
        <Li>
          <p>
            These terms and conditions shall be governed by English law and the
            English Courts shall have exclusive jurisdiction to hear any
            disputes.
          </p>
        </Li>
      </Ol>
    </Card>
  )

  const TuTermsAndConditions = (
    <Card>
      <h1 className="ln-u-color-brand-1 ln-u-text-align-center">
        Prize Draw Terms & Conditions
      </h1>
      <Ol>
        <Li>
          <p>
            This promotion is open to UK residents only (except where the prize
            relates to alcohol in which case Scotland and Northern Ireland are
            excluded) aged 18 and over, who hold a Nectar Card, with the
            exception of employees of the Promoter (defined below) unless it
            applies to an internal colleague promotion, or anyone else
            associated with the administration of this promotion.
          </p>
        </Li>

        <Li>
          <p>
            To enter and opt into the prize draw by answering ‘yes’ to ‘would
            you like to enter our prize draw’ when prompted’.
          </p>
        </Li>
        <Li>
          <p>
            The promotion opens on the first day of each calendar month and
            closes at 23:59 on the last day of each calendar month for receipt
            of all entries.
          </p>
        </Li>
        <Li>
          <p>
            Entries are restricted to one per person per month for the duration
            of the promotion. No entries from agents, third parties or organised
            groups will be accepted. No bulk entries.
          </p>
        </Li>
        <Li>
          <p>
            There are 10 prizes of 100,000 Nectar points to win. No Nectar Card
            primary holder may win more than one prize per month.
          </p>
        </Li>
        <Li>
          <p>
            Where it is a skills based competition at least one judge shall be
            independent. The judges’ decision is final and binding in all
            matters and no correspondence will be entered into. If the prize is
            not judged then the winners will be drawn at random during week
            commencing two weeks following the promotion end date under
            independent supervision. Where the prize is Nectar points the points
            will be automatically allocated to the winner within 14 days of the
            close of the promotion. If you do not wish to receive a prize please
            contact us on 0344 811 0811.
          </p>
        </Li>
        <Li>
          <p>
            The Promoter’s decision is final and binding in all matters and no
            correspondence will be entered into. Where the promotion is run via
            social media including but not limited to Instagram, Facebook and
            Tik Tok then the promotion is no way affiliated with such social
            media platforms.
          </p>
        </Li>
        <Li>
          <p>
            Winners will be notified by either telephone or email or social
            media direct message so therefore entrants will have needed to have
            supplied valid contact details. Winners will be contacted within 28
            days of the closing date. If a winner does not respond when
            contacted on two separate occasions, the prize will be forfeited and
            a back-up winner will be chosen and notified by telephone. The prize
            will be delivered to the winner or arranged within 14 days of prize
            acceptance. Proof of identity may be called for before the prize is
            awarded.
          </p>
        </Li>
        <Li>
          <p>
            Any personal data relating to entrants will be used solely for the
            purposes of this Promotion and/or by any agent appointed to assist
            with running the Promotion on behalf of the Promoter and will not be
            disclosed to any other third party for any other purpose. Entrants’
            personal data will be handled in accordance with Promoter’s this
            privacy policy, available at their website.
          </p>
        </Li>
        <Li>
          <p>
            The Promoter is not responsible or liable for any entries that are
            lost or delayed due to faulty or failed electronic data
            transmissions; communications line failure, regardless of cause,
            with regard to any equipment, systems, networks, lines satellites,
            servers, computers or providers utilised in any aspect of this
            promotion causing delays or disruption; for claims which are lost,
            delayed, corrupted, damaged, misdirected or incomplete or cannot be
            delivered for any technical, delivery or other reason. Proof of
            posting will not be accepted as proof of receipt.
          </p>
        </Li>
        <Li>
          <p>
            There is no alternative to the stated prize or any cash substitute.
            The prize is non-transferable. The Promoter reserves the right to
            substitute the prize for one of greater or equal value.
          </p>
        </Li>
        <Li>
          <p>
            Winners may be requested to participate in any reasonable publicity
            when asked by the Promoter and shall not receive payment for such
            publicity. You may opt-out of publicity. By entering this promotion,
            entrants agree to be bound by these Terms and Conditions governing
            this promotion and by any other requirements set out in related
            promotional materials.
          </p>
        </Li>
        <Li>
          <p>
            Details of the prize winner can be obtained for a period of 12 weeks
            at the Winners page, found at the following link -{' '}
            <a href="./winners/" aria-label="View the winners page">
              https://prizedraw.feedback.tu.sainsburys.co.uk/winners/
            </a>
            . We will publish the surname and county of residence of each
            winner. If a winner objects to the publication of this information
            they may notify the Promoter within 14 days of their original
            winning notification. Without prejudice, the Promoter will provide
            this information to the Advertising Standards Authority on
            reasonable request.
          </p>
        </Li>
        <Li>
          <p>
            If you would like a written copy of these Terms and Conditions,
            please write to the Promoter providing your address and contact
            details.
          </p>
        </Li>
        <Li>
          <p>
            The Promoter reserves the right to vary the Terms and Conditions or
            cancel the draw at any stage in the event of circumstances arising
            beyond its reasonable control. Any question concerning the legal
            interpretation of the rules will be based on English law and the
            Courts of England and Wales will have exclusive jurisdiction.
          </p>
        </Li>
        <Li>
          <p>
            The Promoter is: SAINSBURY’S SUPERMARKETS LTD of 33 Holborn, London
            EC1N 2HT
          </p>
        </Li>
      </Ol>
    </Card>
  )

  switch (brand) {
    case 'argos':
      return ArgosTermsAndConditions
    case 'tu':
      return TuTermsAndConditions
    case 'sainsburys':
    default:
      return SainsburysTermsAndConditions
  }
}

export default TermsAndConditions

const Ol = styled.ol`
  list-style: none;
  counter-reset: item;
  position: relative;
  margin: 0;
`
const Li = styled.li`
  margin-bottom: 16px;
  counter-increment: item;
  text-align: justify;
  position: relative;
  display: flex;
  flex-direction: row;
  > p {
    z-index: 2;
    width: 100%;
  }
  &:before {
    content: counter(item);
    z-index: 2;
    font-size: 2rem;
    color: var(--ds-color-scheme-brand-1-base);
    font-weight: bold;
    padding-right: 10px;
    width: 3rem;
  }
`
