import React from 'react'
import styled from 'styled-components'
import { Link as LinkRouter } from 'gatsby'

interface NavProps {
  pathname: string
}

const Nav: React.FC<NavProps> = ({ pathname }) => {
  return (
    <Wrapper data-testid="nav-links">
      <Link to="/" current={pathname}>
        Terms & Conditions
      </Link>
      <Link to="/winners/" current={pathname}>
        Winners
      </Link>
    </Wrapper>
  )
}

export default Nav

const Wrapper = styled.nav`
  position: relative;

  @media (max-width: 720px) {
    flex: 0;
    text-align: center;
  }
`
const Link = styled(LinkRouter)`
  text-decoration: none;
  display: ${(props: { current: string; to: string }) =>
    props.to === props.current ? 'none' : 'inline'};
  margin: 0 5px;
`
