import React from 'react'
import { GridWrapper, GridItem } from '@jsluna/react'
import WinnersList from '../components/WinnersList'

const Winners: React.FC<{ setTitle: (title: string) => void }> = ({
  setTitle,
}) => {
  setTitle('Winners')

  return (
    <GridWrapper matrix>
      <GridItem size="1/1">
        <WinnersList />
      </GridItem>
    </GridWrapper>
  )
}

export default Winners
