import React from 'react'
import { GridWrapper, GridItem, Card } from '@jsluna/react'

const ErrorPage: React.FC<{ setTitle: (title: string) => void }> = ({
  setTitle,
}) => {
  setTitle('Not Found')

  return (
    <GridWrapper matrix>
      <GridItem size="1/1">
        <Card>
          <h1 className="ln-u-color-brand-1 ln-u-text-align-center">
            Page not found
          </h1>
          <p className="ln-u-text-align-center">
            The page you are looking for does not exist or has been removed.
          </p>
        </Card>
      </GridItem>
    </GridWrapper>
  )
}

export default ErrorPage
