import React, { useState, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { Container } from '@jsluna/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import Header from '../components/Header'
import Hero from '../components/Hero'
import { Brands, getBrandFromHost } from '../data/brands'
import './Layout.scss'

interface LayoutProps {
  pageTitle?: string
  location: Location
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const Layout: React.FC<LayoutProps> = ({ children, location }) => {
  const isProd = process.env.GATSBY_STAGE === 'prod'
  const { pathname, host } = location

  const [theme, setTheme] = useState(getBrandFromHost(''))
  const [pageTitle, setTitle] = useState('')

  useEffect(() => {
    setTheme(getBrandFromHost(host))
  }, [host])

  const brand = useMemo(() => Brands.find((brand) => brand.key === theme), [
    theme,
  ])

  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
        {pageTitle ? (
          <title>{`Lettuce Know - ${pageTitle}`}</title>
        ) : (
          <title>Lettuce Know</title>
        )}
        {isProd ? (
          ''
        ) : (
          <meta
            name="robots"
            content="noindex, nofollow"
            data-test-id="meta-noindex"
          />
        )}
      </Helmet>
      <div className={`ds-theme--${theme}`}>
        <Header pathname={pathname} logo={brand?.logo} brand={brand?.key} />
        <Hero img={brand?.background} />
        <Container soft>
          {React.Children.map(children, (child) => {
            return React.isValidElement(child)
              ? React.cloneElement(child, { setTitle, brand: brand?.key })
              : child
          })}
        </Container>
      </div>
    </QueryClientProvider>
  )
}

export default Layout
