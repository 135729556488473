import { Item, YearMonth } from '../winnersResponse'

const dateSortDesc = (d1: string, d2: string) => {
  if (d1 > d2) return -1
  if (d1 < d2) return 1
  return 0
}
const getUniqueDates = (items: Item[]): YearMonth[] =>
  items
    .map(({ YearMonth }) => YearMonth)
    .filter((x, i, a) => a.indexOf(x) === i)
    .sort(dateSortDesc)

export { dateSortDesc, getUniqueDates }
