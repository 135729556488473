import React from 'react'
import { Container } from '@jsluna/react'
import { Sainsburys } from '@jsluna/images'
import styled from 'styled-components'
import Navigation from './Nav'
import randomLeaf from '../utils/randomLeaf'

interface HeaderProps {
  pathname: string
  logo?: string
  brand?: string
}

const Header: React.FC<HeaderProps> = ({
  pathname,
  logo = Sainsburys,
  brand,
}) => {
  const BrandLogo = styled(logo)`
    margin-top: 10px;
    height: 20px;

    @media (min-width: 720px) {
      height: 30px;
    }

    @media (max-width: 720px) {
      display: none;
    }
  `

  let brandText

  switch (brand) {
    case 'argos':
    case 'tu':
      brandText = 'Prize Draw'
      break
    case 'sainsburys':
    default:
      brandText = 'Lettuce Know'
      break
  }

  return (
    <Wrapper>
      <Container>
        <HeaderContent>
          <H1 className="ln-u-color-brand-1">{brandText}</H1>
          <Navigation pathname={pathname} />
          <BrandLogo />
        </HeaderContent>
      </Container>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.div`
  background: white;
  border-bottom: 1px solid #8e8e8e;
  margin-bottom: 1rem;
`
const HeaderContent = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 5px;
    right: 0;
    bottom: 5px;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.6;
    @media (min-width: 720px) {
      left: -15px;
    }
    .ds-theme--sainsburys & {
      background-image: url(${randomLeaf});
    }
  }
`

const H1 = styled.h1`
  display: inline-block;
  margin: 0;
  z-index: 5;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  @media (max-width: 720px) {
    font-size: 28px;
    flex: 1 0 auto;
  }
`
