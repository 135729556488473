import React from 'react'
import styled from 'styled-components'

interface WinnerProps {
  winner: {
    County: string
    YearMonth: string
    Name: string
  }
}

const Winner: React.FC<WinnerProps> = ({ winner }) => (
  <Wrapper>
    <FirstLine>{winner.Name}</FirstLine>&nbsp;
    <SecondLine>from {winner.County}</SecondLine>
  </Wrapper>
)

export default Winner

const Wrapper = styled.span`
  padding: 5px 10px;
  display: block;
  text-align: center;
`
const FirstLine = styled.span`
  text-align: center;
  font-size: 18px;
`
const SecondLine = styled.span`
  text-align: center;
  font-size: 14px;
`
