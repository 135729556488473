import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import axios from 'axios'
import { Card } from '@jsluna/react'
import { getMonth, getYear, getMonthName } from '../utils/date'
import Winner from './Winner'
import { getUniqueDates } from '../utils/helpers'
import { Item, YearMonth, WinnersResponse } from '../winnersResponse'

const Header = () => (
  <h1 className="ln-u-color-brand-1 ln-u-text-align-center">Winners</h1>
)

const WinnersList: React.FC = () => {
  const [winners, setWinners] = useState<Item[]>([])
  const [dates, setDates] = useState<YearMonth[]>([])
  const [isEmpty, setIsEmpty] = useState(false)

  const { data, error, isFetching } = useQuery<WinnersResponse, Error>(
    'winners',
    async () => {
      const winnersApi = process.env.GATSBY_WINNERS_API
      if (!winnersApi) {
        throw new Error('Undefined GATSBY_WINNERS_API')
      }

      const { data } = await axios.get(winnersApi)
      return data
    }
  )

  useEffect(() => {
    if (data) {
      const { items } = data
      if (items.length === 0) {
        setIsEmpty(true)
      } else {
        setWinners(items)
        setDates(getUniqueDates(items))
      }
    }
  }, [data, error])

  if (isFetching) {
    return (
      <CardMod data-testid="loading">
        <Header />
        <SubHeader className="ln-u-color-brand-1">
          Loading winners, please wait.
        </SubHeader>
      </CardMod>
    )
  }

  if (error) {
    return (
      <CardMod data-testid="error">
        <Header />
        <SubHeader className="ln-u-color-brand-1">
          An error has occurred. Please try again.
        </SubHeader>
      </CardMod>
    )
  }

  if (isEmpty) {
    return (
      <CardMod data-testid="no-winners">
        <Header />
        <SubHeader className="ln-u-color-brand-1">
          No winners yet, please come back later.
        </SubHeader>
      </CardMod>
    )
  }

  return (
    <CardMod data-testid="draw">
      <Header />
      {dates.map((date) => (
        <React.Fragment key={date}>
          <SubHeader className="ln-u-color-brand-1">
            {`${getMonthName(getMonth(date))}, ${getYear(date)}`}
          </SubHeader>
          <WinnersWrapper>
            {winners.map((winner: any, index) => {
              if (winner.YearMonth === date) {
                return <Winner winner={winner} key={index + winner.YearMonth} />
              }
              return null
            })}
          </WinnersWrapper>
        </React.Fragment>
      ))}
    </CardMod>
  )
}

export default WinnersList

const WinnersWrapper = styled.div`
  display: block;
`

const SubHeader = styled.h2`
  text-align: center;
  margin: 10px 0 0 0;
`

const CardMod = styled(Card)`
  min-height: 280px;
`
