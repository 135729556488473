import {
  Sainsburys as SainsburysLogo,
  ArgosAppLogoWithUrl as ArgosLogo,
  Tu as TuLogo,
} from '@jsluna/images'
import SainsburysHeroBg from '../images/bg.png'

export interface BrandInterface {
  name: string
  key: string
  logo: string
  background: string
}

export const Brands: BrandInterface[] = [
  {
    name: "Sainsbury's",
    key: 'sainsburys',
    logo: SainsburysLogo,
    background: SainsburysHeroBg,
  },
  { name: 'Argos', key: 'argos', logo: ArgosLogo, background: '' },
  { name: 'Tu', key: 'tu', logo: TuLogo, background: '' },
]

export const getBrandFromHost = (location: string) => {
  switch (location) {
    case 'lettuce-knowprizedraw.sainsburys.co.uk':
    case 'dev.lettuceknow.cmdtests.com':
      return 'sainsburys'
    case 'prizedraw.feedback.argos.co.uk':
    case 'prizedraw.feedback.argos.dev.lettuceknow.cmdtests.com':
      return 'argos'
    case 'prizedraw.feedback.tu.sainsburys.co.uk':
    case 'prizedraw.feedback.tu.argos.co.uk':
    case 'prizedraw.feedback.tu.dev.lettuceknow.cmdtests.com':
      return 'tu'
    default:
      return 'sainsburys'
  }
}
