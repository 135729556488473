import React from 'react'
import styled from 'styled-components'

interface HeroProps {
  img?: string
}

const Hero: React.FC<HeroProps> = ({ img = '' }) => <Wrapper img={img} />

export default Hero

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(194, 194, 194, 0) 79%,
      rgba(242, 242, 242, 1) 100%
    ),
    url(${(props: { img: string }) => (props.img ? props.img : '')});
  background-size: 100%, 300px;
  background-position: bottom, center;
  top: -1rem;
  z-index: -1;
  margin-bottom: -400px;

  @media (max-width: 720px) {
    display: none;
  }
`
