import moment from 'moment'

type Date = string

const formatDate = (date: Date) => moment(date, 'YYYYMM')

const getMonth = (date: Date): string => moment(formatDate(date)).format('MM')

const getMonthName = (date: Date): string => moment(date, 'MM').format('MMMM')

const getYear = (date: Date): string => moment(formatDate(date)).format('YYYY')

const getTimeStamp = (date: Date): string => moment(date, 'YYYYMM').format('x')

export { getMonth, getMonthName, getYear, getTimeStamp }
