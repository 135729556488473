import React from 'react'
import { GridWrapper, GridItem } from '@jsluna/react'
import TermsAndConditions from '../components/TermsAndConditions'

const Home: React.FC<{ setTitle: (title: string) => void; brand: string }> = ({
  setTitle,
  brand,
}) => {
  setTitle('Terms and Conditions')

  return (
    <GridWrapper matrix>
      <GridItem size="1/1">
        <TermsAndConditions brand={brand} />
      </GridItem>
    </GridWrapper>
  )
}

export default Home
